import React from "react"
import { Link, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import { ArrowLeft, ArrowRight } from "react-feather"
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


import Layout from "../components/layout"
import SEO from "../components/seo"



class CaseStudyTemplate extends React.Component {
  render() {
    const Bold = ({ children }) => <span className="bold">{children}</span>;
    const Text = ({ children }) => <p>{children}</p>;

    const options = {
      renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
      },
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          return  <img  
                    className="body-asset"
                    src={node.data.target.fields.file['en-US'].url} 
                    title={node.data.target.fields.title['en-US']}
                    alt={node.data.target.fields.description['en-US']}
                  />
        },
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      },
    };

    const project = this.props.data.contentfulCaseStudy
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    const ProjectHero = styled.div`
      h1 {
        font-weight: 700;
        margin: 100px 0;
        text-align: center;
        color: #1c1c1c;
        font-size: 52px;
        line-height: 1.2;
      }
    `

    const ProjectBody =styled.div`
      padding: 20px 20px;
      width:100%;
      max-width: 900px;
      margin: 0;
      box-sizing: border-box;

      p, li {
        font-size: 18px;
      }

      li>p {
        margin: 0;
      }

      .body-asset {
        width: 100%;
      }

      /* Small devices (landscape phones, 576px and up)*/
      @media (min-width: 576px) { 

        .body-asset {
          padding: 0 20px;
        }
      }

      /* Medium devices (tablets, 768px and up)*/
      @media (min-width: 768px) {
        
      }

      /* Large devices (desktops, 992px and up)*/
      @media (min-width: 992px) {
        margin: 0 auto;
        
      }

      /* Large devices (desktops, 992px and up)*/
      @media (min-width: 1200px) {
      }
    `

    const CaseSummaryMobile = styled.div`
      float: left;
      background-color:  #061338;
      padding: 20px;
      color: #ebebeb;
      width: 100%;
      margin-bottom: 20px;

      p, li {
        font-size: 16px;
        font-weight: 100;
      }

      li>p {
        margin: 0;
      }

      /* Small devices (landscape phones, 576px and up)*/
      @media (min-width: 576px) { 
        display: none;
      }

    `

    const CaseSummary = styled.div`
      float: left;
      margin-right: 16px;
      margin-bottom: 16px;
      background-color:  #061338;
      padding: 10px;
      color: #ebebeb;
      border-radius: 5px;
      width: 210px;
      display: none;

      p, li {
        font-size: 16px;
        font-weight: 100;
      }

      li>p {
        margin: 0;
      }
      /* Small devices (landscape phones, 576px and up)*/
      @media (min-width: 576px) { 
        display: block;
      }

      /* Medium devices (tablets, 768px and up)*/
      @media (min-width: 768px) {
        
      }

      /* Large devices (desktops, 992px and up)*/
      @media (min-width: 992px) {
        
      }

      /* Large devices (desktops, 992px and up)*/
      @media (min-width: 1200px) {
      }
    `

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={project.caseStudyTitile}
        />
        <ProjectHero>
          <BackgroundImage Tag="section"
                           className="HeroGroup ProjectHeroGroup"
                           fluid={project.heroImage.fluid}
                           backgroundColor={`#ebebeb`}
          >
            <h1>{project.caseStudyTitile}</h1>
          </BackgroundImage>
        </ProjectHero>
        <CaseSummaryMobile>
            {documentToReactComponents(project.childContentfulCaseStudySummaryBoxRichTextNode.json, options)}
        </CaseSummaryMobile>
        <ProjectBody>
          <CaseSummary>
            {documentToReactComponents(project.childContentfulCaseStudySummaryBoxRichTextNode.json, options)}
          </CaseSummary>
          {documentToReactComponents(project.childContentfulCaseStudyCaseStudyBodyRichTextNode.json, options)}
        </ProjectBody>
        <ul className="CaseNav">
          <li>
            {previous && (
              <Link to={previous.slug} rel="prev">
                <ArrowLeft/> {previous.caseStudyTitile}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.slug} rel="next">
                {next.caseStudyTitile} <ArrowRight/>
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    )
  }
}

export default CaseStudyTemplate

export const pageQuery = graphql`
  query CaseStudyBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    contentfulCaseStudy( slug: { eq: $slug }) {
        caseStudyTitile
        heroImage {
            fluid {
                src
            }
        }
        childContentfulCaseStudySummaryBoxRichTextNode {
          json
        }
        childContentfulCaseStudyCaseStudyBodyRichTextNode {
          json
        }
    }
  }
`